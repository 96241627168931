<template>
  <div class="flex full w-full relative">
    <Three v-if="valid === true" />
  </div>
</template>

<script>
import Three from '@/pages/index/components/three/Index.vue';
import useStatus from '@/components/useStatus';
import ExhibService from '@/services/Exhib';
import { useRoute } from 'vue-router';
export default {
  components: { Three },
  setup() {
    const route = useRoute();

    const { valid, setValid } = useStatus();
    ExhibService.simple({ id: route.params.id }).then((res) => {
      setValid(res.code === 1 ? res.data.status : res.code);
    });

    return { valid };
  },
};
</script>
